<template>
  <TTModuleMenu
    v-if="!$vuetify.breakpoint.xsOnly"
    :items-tree="menuItems"
    :sign-out-text="$t('common.sign_out')"
    @sign-out="handleSignOut"
  />
  <VBottomNavigation
    v-else
    fixed
    app
    shift
    color="tt-primary"
    :hide-on-scroll="false"
  >
    <!-- Баг с навигацией https://stackoverflow.com/questions/69136077/vuetify-bottom-navigation-not-removing-active-state-on-route-change-after-hard-r -->

    <VBtn
      v-for="(item,key) in menuItems"
      ref="link"
      :key="`route-${key}`"
      :to="item.to"
      plain
      active-class=""
      :disabled="item.disabled"
    >
      <span class="">{{ item.title }}</span>

      <VIcon>{{ item.icon }}</VIcon>
    </VBtn>
  </VBottomNavigation>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { accessToken } from '@/services/cookies';
import { R_APP_CHAT } from '@/plugins/vue-router/routeNames';

export default {
  name: 'AppNavigationPanel',

  inject: ['Names', 'glFeatures'],
  data() {
    return {
      currentPage: '',
    };
  },
  computed: {
    test() {
      return this.$router.getRoutes();
    },
    // currentPage: {
    //   get() {
    //     return this.$route.params.name;
    //   },
    //   set(value) {
    //     this.currentPage = value;
    //   },
    // },

    ...mapState('settings', ['isTutor']),
    ...mapGetters('handbook', ['hasUnreadEvents']),
    ...mapGetters('settings', ['accessToGoals']),
    ...mapGetters('user', ['isRequestEmail', 'isEmptyEmail']),
    newTracksPage() {
      return this.glFeatures('new_tracks_page');
    },
    menuItems() {
      const menu = [];
      const disabled = (this.$route.name === R_APP_CHAT);

      menu.push({
        id: 1,
        title: this.$t('menu.levels'),
        to: { name: this.Names.R_APP_LEVELS },
        icon: 'fal fa-route',
        testLabel: 'menu-levels',
        disabled,
      });
      if (this.newTracksPage) {
        menu.push({
          id: 0,
          title: this.$t('menu.tracks'),
          to: { name: this.Names.R_APP_TRACKS },
          icon: 'fal fa-route',
          testLabel: 'menu-tracks',
          disabled,
        });
      }

      if (this.isTutor) {
        menu.push({
          id: 2,
          title: this.$t('menu.trainees'),
          to: { name: this.Names.R_APP_TRAINEES },
          icon: 'fal fa-user-friends',
          testLabel: 'menu-trainees',
          disabled,
        });
      }
      if (this.accessToGoals) {
        menu.push({
          id: 3,
          title: this.$t('menu.goals'),
          to: { name: this.Names.R_APP_GOALS },
          icon: 'fal fa-bullseye-pointer',
          testLabel: 'menu-goals',
          disabled,
        });
      }
      menu.push({
        id: 4,
        title: this.$t('menu.handbook'),
        to: { name: this.Names.R_APP_HANDBOOK },
        icon: 'fal fa-book',
        badge: this.hasUnreadEvents,
        testLabel: 'menu-handbook',
        disabled,
      });
      menu.push({
        id: 5,
        title: this.$t('menu.profile'),
        to: { name: this.Names.R_APP_PROFILE },
        icon: 'fal fa-user',
        badge: this.isRequestEmail && this.isEmptyEmail,
        testLabel: 'menu-profile',
        disabled,
      });
      if (!this.$vuetify.breakpoint.xsOnly) {
        return [
          menu,
        ];
      }
      return menu;
    },
  },
  methods: {
    handleClickMenuItem(route) {
      this.$router.push(route);
    },
    clearSkippedTasks() {
      localStorage.removeItem('skippedTaskItem');
    },
    handleSignOut() {
      accessToken.remove();
      this.clearSkippedTasks();
      window.location.replace('/');
    },
  },
};
</script>
<style lang="scss">
.floating-button {
  position: fixed;
  z-index: 10;
  bottom: 64px;
  right: 16px;
}
</style>
