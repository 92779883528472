<template>
  <TTChip
    v-if="chip"
    :color="chip.color"
    :outlined="false"
    small
    :data-test-label="`chip-status-${status}`"
  >
    <VIcon
      v-if="showIcon"
      left
      :color="chip.iconColor"
      data-test="chip-status-icon"
    >
      {{ chip.icon }}
    </VIcon>
    <span
      class="tt-text-caption"
      data-test="chip-status-text"
      :class="chip.textColor"
    >
      {{ chip.text }}
    </span>
  </TTChip>
</template>

<script>
import {
  chipStatusTypes,
  COMPLETED, FAILED, NEW, REJECTED, REQUIRED, SKIPPED,
} from '@/helpers/constants';

export default {
  name: 'ChipStatus',
  props: {
    itemType: {
      type: String,
      validator: (v) => Object.values(chipStatusTypes).includes(v),
      default: () => chipStatusTypes.ITEM,
    },
    status: {
      type: String,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    date: {
      type: [String],
      default: null,
    },
  },
  computed: {
    diffDate() {
      const monthCount = this.diffDates({ date: this.date, nowDate: this.$dayjs(), format: 'M' });
      const daysCount = this.diffDates({ date: this.date, nowDate: this.$dayjs(), format: 'd' });
      return {
        count: monthCount > 0 ? monthCount : daysCount,
        text: monthCount > 0 ? this.$tc('pluralize.months', monthCount) : this.$tc('pluralize.days', daysCount),
      };
    },
    chip() {
      // TODO Найти иконки для остальных статусов, сейчас нет кейса для остальных статусов
      if (this.itemType === chipStatusTypes.PURPOSE) {
        switch (this.status) {
          case NEW: {
            return {
              color: this.checkDiffDays(
                this.diffDate.count,
                'tt-light-red pale',
                'tt-light-blue pale',
                'tt-light-yellow pale',
              ),
              textColor: this.checkDiffDays(
                this.diffDate.count,
                'tt-light-red--text',
                'tt-light-blue--text',
                'tt-light-yellow--text',
              ),
              text: this.diffDate.text,
              icon: this.checkDiffDays(this.diffDate.count, 'fas fa-fire', null, 'fas fa-clock'),
              iconColor: this.checkDiffDays(this.diffDate.count, 'tt-light-red', 'tt-light-blue', 'tt-light-yellow'),
            };
          }
          case COMPLETED: {
            return {
              color: 'tt-light-green pale',
              textColor: 'tt-light-green--text',
              text: this.$t('item_state.achieved'),
              icon: 'fas fa-check-circle',
              iconColor: 'tt-light-green',
            };
          }
          case REJECTED: {
            return {
              color: 'tt-light-mono-16',
              textColor: 'tt-light-mono-64--text',
              text: this.$t('item_state.rejected'),
              icon: 'fas fa-archive',
              iconColor: 'tt-light-mono-64',
            };
          }
          case FAILED: {
            return {
              color: 'tt-light-orange pale',
              textColor: 'tt-light-orange--text',
              text: this.$t('item_state.failed'),
              icon: 'fas fa-times-circle',
              iconColor: 'tt-light-orange',
            };
          }
          default:
            return null;
        }
      }
      if (this.itemType === chipStatusTypes.EVENT) {
        switch (this.status) {
          case REQUIRED: {
            return {
              color: 'tt-light-orange pale',
              textColor: 'tt-light-orange--text',
              text: this.$t('item_state.required'),
              icon: 'fas fa-check-circle',
              iconColor: 'tt-light-orange',
            };
          }
          case COMPLETED: {
            return {
              color: 'tt-light-mono-4',
              textColor: 'tt-light-mono-64--text',
              text: this.$t('item_state.completed'),
              icon: 'fas fa-check-circle',
              iconColor: 'tt-light-green vibrant',
            };
          }
          case SKIPPED: {
            return {
              color: 'tt-light-mono-8',
              textColor: 'tt-light-mono-64--text',
              text: this.$t('item_state.skipped'),
              icon: 'fas fa-check-circle',
              iconColor: 'tt-light-mono-8',
            };
          }
          default:
            return null;
        }
      } else {
        switch (this.status) {
          case REQUIRED: {
            return {
              color: 'tt-light-orange pale',
              textColor: 'tt-light-orange--text',
              text: this.$t('item_state.required'),
              icon: 'fas fa-check-circle',
              iconColor: 'tt-light-orange',
            };
          }
          case COMPLETED: {
            return {
              color: 'tt-light-green pale',
              textColor: 'tt-light-green--text',
              text: this.$t('item_state.done'),
              icon: 'fas fa-check-circle',
              iconColor: 'tt-light-green',
            };
          }
          case SKIPPED: {
            return {
              color: 'tt-light-mono-8',
              textColor: 'tt-light-mono-64--text',
              text: this.$t('item_state.skipped'),
              icon: 'fas fa-check-circle',
              iconColor: 'tt-light-mono-8',
            };
          }
          default:
            return null;
        }
      }
    },
  },
  methods: {
    checkDiffDays(dayDiff, lessSolution, equalSolution, moreSolution) {
      if (dayDiff === 0) {
        return equalSolution;
      }
      if (dayDiff > 0) {
        return moreSolution;
      }
      return lessSolution;
    },
    diffDates({ date, nowDate = this.$dayjs(), format = 'd' }) {
      if (!date) {
        return null;
      }
      const comparableDate = this.$dayjs(date).format('YYYY-MM-DD');
      const todayDate = this.$dayjs(nowDate).format('YYYY-MM-DD');

      return this.$dayjs(comparableDate).diff(todayDate, format);
    },
  },
};

</script>
