<template>
  <canvas
    ref="canvas"
    :width="width"
    :height="height"
    @click="$emit('click')"
  />
</template>
<script>
import canvasConfetti from 'canvas-confetti';

export default {
  name: 'ConfettiCannon',
  props: {
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    realisticLook: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  watch: {
    realisticLook(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.playRealisticLook();
      }
    },
  },
  mounted() {
    const { canvas } = this.$refs;
    this.confetti = canvasConfetti.create(canvas, {
      resize: false,
      useWorker: true,
    });
    if (this.realisticLook) {
      // TODO без задержки может кинуть canvas.getBoundingClientRect is not a function
      setTimeout(() => this.playRealisticLook(), 0);
    }
  },
  methods: {
    fire(particleRatio, opts, count = 200) {
      const def = { origin: { y: 0.7 } };
      this.confetti({ ...def, ...opts, particleCount: Math.floor(count * particleRatio) });
    },
    playRealisticLook(count = 200) {
      this.fire(0.25, {
        spread: 26,
        startVelocity: 55,
      }, count);
      this.fire(0.2, {
        spread: 60,
      }, count);
      this.fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      }, count);
      this.fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      }, count);
      this.fire(0.1, {
        spread: 120,
        startVelocity: 45,
      }, count);
    },
  },
};
</script>
