<template>
  <VSnackbar
    v-if="snackbar.value"
    class="tt-text-body-1 snackbar"
    :value="snackbar.value"
    :timeout="3000"
    right
    top
    color="tt-light-mono-0 tt-light-mono-100--text"
    @input="toggleSnack"
  >
    <VIcon
      v-if="snackbar.status === 'success'"
      class="mr-2"
      size="20"
      color="tt-light-green vibrant"
    >
      fas fa-check-circle
    </VIcon>

    <VIcon
      v-else
      class="mr-2"
      size="20"
      color="tt-light-red"
    >
      fas fa-times-circle
    </VIcon>

    {{ snackbar.message }}

    <template #action="{ attrs }">
      <VBtn
        icon
        v-bind="attrs"
        @click="toggleSnack"
      >
        <VIcon color="tt-light-mono-100">
          fal fa-times
        </VIcon>
      </VBtn>
    </template>
  </VSnackbar>
</template>

<script>
// TODO: переделать на TTNotify

import { mapState, mapMutations } from 'vuex';
import { toggleSnack } from '@/plugins/vuex/mutationTypes';

export default {
  name: 'AppSnackbar',

  computed: {
    ...mapState(['snackbar']),
  },

  methods: {
    ...mapMutations([toggleSnack]),
  },
};
</script>

<style lang="scss" scoped>
.snackbar {
  ::v-deep {
    .v-snack__content {
      font-weight: 500 !important;
    }
    .v-snack__wrapper {
      box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
      border: 1px solid #EBECED;
    }
  }
}
</style>
