<template>
  <VApp>
    <VMain>
      <Transition
        name="fade"
        mode="out-in"
      >
        <RouterView />
      </Transition>
    </VMain>
    <AppAsideLeft />
    <AppSnackbar />
    <DialogHappyState
      v-bind="activeHappyState"
      @close="closeDialogHappyState"
    />
  </VApp>
</template>

<script>
import AppAsideLeft from '@/components/app/AppNavigationPanel.vue';
import AppSnackbar from '@/components/app/AppSnackbar.vue';
import DialogHappyState from '@/components/track/DialogHappyState.vue';
import { mapActions, mapGetters } from 'vuex';
import { SET_HAPPY_STATE_SHOW_ACTION } from '@/plugins/vuex/actionTypes';
import { profileGet } from '@/services/api/snami';

export default {
  name: 'MobileLayout',
  components: { DialogHappyState, AppSnackbar, AppAsideLeft },
  computed: {
    ...mapGetters('happyState', ['activeHappyState']),
  },
  async created() {
    await this.getProfile();
  },
  methods: {
    ...mapActions('happyState', { setHappyStateShow: SET_HAPPY_STATE_SHOW_ACTION }),
    async getProfile() {
      try {
        const { data } = await profileGet();
        this.saveProfileIdToLocalStorage(data.id);
      } catch (e) {
        console.warn(e);
      }
    },
    saveProfileIdToLocalStorage(id) {
      const savedId = JSON.parse(localStorage.getItem('userId'));
      if (savedId === id) return;
      localStorage.setItem('userId', JSON.stringify(id));
    },
    closeDialogHappyState() {
      this.setHappyStateShow(false);
    },
  },
};
</script>

<style scoped>

</style>
