<template>
  <VDialog
    :value="show"
    content-class="happy-state"
    max-width="372px"
    overlay-color="#F5F6F6"
    :overlay-opacity="0.8"
    :transition="false"
    @input="close"
  >
    <ConfettiCannon
      v-if="show"
      class="confetti-cannon"
      :realistic-look="show"
      :width="800"
      :height="800"
      @click="close"
    />
    <ItemWrapper class="px-9 item-wrapper">
      <VImg
        v-if="iconType"
        class="mx-auto"
        :width="230"
        :height="160"
        :src="iconType"
        data-test="happy-state-icon"
        data-test-value=""
      />
      <VImg
        v-else-if="iconUrl"
        class="mx-auto mb-4"
        contain
        :width="120"
        :height="120"
        :src="iconUrl"
        data-test="happy-state-img"
        data-test-value=""
      />
      <div
        v-if="title"
        data-test="happy-state-title"
        class="text-center tt-text-title-1"
      >
        {{ title }}
      </div>
      <div
        v-if="message"
        data-test="happy-state-message"
        class="text-center tt-text-body-1 mt-2"
      >
        {{ message }}
      </div>
      <div
        v-if="description"
        class="text-center tt-text-caption mt-1"
        data-test="happy-state-description"
      >
        {{ description }}
      </div>

      <div class="d-flex justify-center mt-7">
        <TTBtn
          plain
          class="mx-auto"
          data-test-label="happy-state-btn"
          @click="close"
        >
          {{ buttonText }}
        </TTBtn>
      </div>
    </ItemWrapper>
  </VDialog>
</template>

<script>
import ItemWrapper from '@/components/task/ItemWrapper.vue';
import ConfettiCannon from '@/components/track/ConfettiCannon.vue';

export default {
  name: 'DialogHappyState',
  components: { ItemWrapper, ConfettiCannon },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    iconUrl: {
      type: String,
      default: '',
    },
    iconType: {
      type: [String, Object],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

::v-deep .happy-state {
  overflow: unset;
  box-shadow: none;
  background: rgba(0, 0, 0, 0);
}
.confetti-cannon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto !important;
  z-index: 77777;
}
.item-wrapper {
  position: relative;
  z-index: 77778;
}
</style>
