<template>
  <VCard
    class="item__wrapper"
    elevation="0"
  >
    <div
      v-if="status"
      class="pb-2"
    >
      <ChipStatus :status="status" />
    </div>
    <slot name="default" />
  </VCard>
</template>

<script>
import ChipStatus from '@/components/chips/ChipStatus.vue';

export default {
  name: 'ItemWrapper',
  components: { ChipStatus },
  props: {
    status: {
      type: [String, Boolean],
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.item__wrapper{
  border-radius: 16px;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08)!important;
  padding: 20px;
}
</style>
