<template>
  <Component :is="layout">
    <slot />
  </Component>
</template>

<script>
import HealthyLayout from '@/layouts/app/HealthyLayout.vue';
import MobileLayout from '@/layouts/app/MobileLayout.vue';

export default {
  name: 'DynamicLayout',
  components: {
    HealthyLayout,
    MobileLayout,
  },
  computed: {
    layout() {
      const layout = 'HealthyLayout';
      const mobileLayout = 'MobileLayout';
      if (this.$route.meta.layout) {
        return this.$route.meta.layout;
      }
      return this.isMobile ? mobileLayout : layout;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style scoped>

</style>
