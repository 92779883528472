<template>
  <VApp id="HealthyLayout">
    <RouterView
      name="nav"
    />

    <VMain :class="withMenu">
      <VContainer
        fluid
        class="mt-3"
      >
        <VRow align="start">
          <VCol
            :cols="$vuetify.breakpoint.xsOnly? 6 : 1"
            :order="1"
          >
            <slot name="left">
              <Transition
                name="fade"
                mode="out-in"
              >
                <RouterView name="tt-view-left" />
              </Transition>
            </slot>
          </VCol>
          <VCol :order="$vuetify.breakpoint.xsOnly? 3 : 2">
            <slot name="default">
              <Transition
                name="fade"
                mode="out-in"
              >
                <RouterView />
              </Transition>
            </slot>
          </VCol>
          <VCol
            :cols="$vuetify.breakpoint.xsOnly? 6 : 1"
            :order="$vuetify.breakpoint.xsOnly? 2 : 3"
          >
            <slot name="right">
              <TransitionGroup
                name="fade"
                mode="out-in"
              >
                <PortalTarget
                  key="portal"
                  name="tt-view-right"
                />
                <RouterView
                  key="router"
                  name="tt-view-right"
                />
              </TransitionGroup>
            </slot>
          </VCol>
        </VRow>
      </VContainer>
    </VMain>
    <VFooter v-if="$slots['content-footer']">
      <slot name="content-footer">
        <Transition
          name="fade"
          mode="out-in"
        >
          <RouterView name="content-footer" />
        </Transition>
      </slot>
    </VFooter>

    <RouterView
      name="aside-left"
    />
    <RouterView
      name="aside-right"
    />

    <RouterView name="footer" />
    <AppSnackbar />
    <DialogHappyState
      v-bind="activeHappyState"
      @close="closeDialogHappyState"
    />
  </VApp>
</template>

<script>
import DialogHappyState from '@/components/track/DialogHappyState.vue';
import AppSnackbar from '@/components/app/AppSnackbar.vue';
import { mapActions, mapGetters } from 'vuex';
import { SET_HAPPY_STATE_SHOW_ACTION } from '@/plugins/vuex/actionTypes';
import { profileGet } from '@/services/api/snami';

export default {
  name: 'HealthyLayout',
  components: {
    DialogHappyState,
    AppSnackbar,
  },
  data: () => ({}),
  computed: {
    ...mapGetters('happyState', ['activeHappyState']),
    withMenu() {
      if (this.$vuetify.application.left) {
        return {
          'tt-main__xl_menu': this.$vuetify.breakpoint.xl,
          'tt-main__lg_menu': this.$vuetify.breakpoint.lg,
          'tt-main__md_menu': this.$vuetify.breakpoint.md,
          'tt-main__sm_menu': this.$vuetify.breakpoint.sm,
        };
      }
      return {};
    },
  },
  async created() {
    await this.getProfile();
  },
  methods: {
    ...mapActions('happyState', { setHappyStateShow: SET_HAPPY_STATE_SHOW_ACTION }),
    async getProfile() {
      try {
        const { data } = await profileGet();
        this.saveProfileIdToLocalStorage(data.id);
      } catch (e) {
        console.warn(e);
      }
    },
    saveProfileIdToLocalStorage(id) {
      const savedId = JSON.parse(localStorage.getItem('userId'));
      if (savedId === id) return;
      localStorage.setItem('userId', JSON.stringify(id));
    },
    closeDialogHappyState() {
      this.setHappyStateShow(false);
    },
  },
};
</script>

<style scoped>

</style>
